import React from 'react'
import './help.css'

function Help() {
    return (
        <div className="help">
            ASSISTANCE
        </div>
    )
}

export default Help
