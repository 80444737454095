import React from 'react'
import './pro.css'

function Pro() {
    return (
        <div className="pro">
            POUR LES PARTENAIRES
        </div>
    )
}

export default Pro
