import React from 'react'
import './where.css'

function Where() {
    return (
        <div className="where">
            OÙ ACHETER
        </div>
    )
}

export default Where
