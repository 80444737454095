import React from 'react'
import './product.css'

function Product() {
    return (
        <div className="product">
            PRODUITS
        </div>
    )
}

export default Product
