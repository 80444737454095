import React from 'react'
import './blog.css'

function Blog() {
    return (
        <div className="blog">
            BLOG
        </div>
    )
}

export default Blog
