import React from 'react'
import './software.css'

function Software() {
    return (
        <>
            <div className="software">
                LOGICIELS
            </div>
        </>
    )
}

export default Software
